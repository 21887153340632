import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import moment from "moment";

//compoenents survey
import NPSComponent from "./Components/NPS";
import CSATComponent from "./Components/CSAT";
import CESComponent from "./Components/CES";
import Radiobox from "./Components/Radiobox";
import Checkbox from "./Components/Checkbox";
import PlainText from "./Components/PlainText";
import CascadingDropdown from "./Components/CascadingDropdown";
import Dropdown from "./Components/Dropdown";
import Textarea from "./Components/Textarea";
import Button from "./Components/Button";
import Optin from "./Components/Optin";
import Input from "./Components/Input";
import InputPhone from "./Components/Input/Phone";
import InputCPF from "./Components/Input/CPF";
import InputEmail from "./Components/Input/Email";
import DateTime from "./Components/Input/DateTime";
import DropdownLanguage from "./Components/DropdownLanguage";
import ImageUpload from "./Components/ImageUpload";
import ResetButton from "./Components/ResetButton";
import AutoComplete from "./Components/AutoComplete";
import Mosaic from "./Components/Mosaic";
import Action from "./Components/Action";

//images
import LogoInovyoSurvey from "../../assets/images/inovyo-survey.svg";

//styles
import "../../assets/css/survey/survey.css";

//utils
import {
  setStorage,
  getStorage,
  createStorageIfNotExist,
  deleteStorage,
} from "../../utils/Storage";
import { getLuminosity } from "../../utils/String";

//redux
import {
  surveyData,
  maxPage,
  userResponses,
  setSurveyData,
  setUserReponses,
  errorAPI,
  setErrors,
  setUpdate,
  update,
  loading,
  kioski,
  setMaxPage,
  errors,
} from "../../store/survey/survey.slice";
import {
  initDataSurvey,
  sendDataToDB,
} from "../../store/survey/survey.actions";

//validates
import {
  validatePreviousPage,
  validateNextPage,
  checkMergeCodes,
  extractPersistAnswerOnPage,
} from "./validates";

const StaticSurvey = () => {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const dataRedux = useSelector(surveyData);
  const maxPageRedux = useSelector(maxPage);
  const updateRedux = useSelector(update);
  const userResponsesRedux = useSelector(userResponses);
  const errorAPIRedux = useSelector(errorAPI);
  const listError = useSelector(errors);
  const myLoading = useSelector(loading);
  const kioskiRedux = useSelector(kioski);
  const { surveyid, responseid } = useParams();
  const [logoHeader, setLogoHeader] = useState("");
  const [animateBack, setAnimateBack] = useState(false);
  const [animateForward, setAnimateForward] = useState(false);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const surveyDataStorage = getStorage(surveyid);
    if (surveyDataStorage === "") {
      createStorageIfNotExist(
        surveyid,
        {
          time: moment().unix(),
        },
        true
      );
    }

    dispatch(
      initDataSurvey({ surveyid, responseid, npsQuery: params.get("nps") })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (kioskiRedux) {
      let surveyData = getStorage(surveyid, true);

      var sleepCount = 0;
      var restartCount = 0;
      var currentPageFreeze = 0;

      const resetCount = () => {
        sleepCount = 0;
      };

      ["mousemove", "keyup", "touchmove", "wheel"].forEach((ev) => {
        window.addEventListener(ev, resetCount, false);
      });

      const calculateKioski = () => {
        let { sleep, restart } = surveyData.survey.kioski;
        let { currentPage } = surveyData.user;

        if (currentPage !== 0) {
          if (currentPageFreeze !== currentPage) {
            currentPageFreeze = currentPage;
          }

          if (sleepCount === sleep) {
            sleepCount = 0;
            deleteStorage(surveyid);
            window.location.href = `${window.location.href}`;
            return;
          } else {
            sleepCount += 1;
          }

          if (surveyData.survey.pages.length - 1 === currentPage) {
            if (restart === restartCount) {
              restartCount = 0;
              deleteStorage(surveyid);
              window.location.href = `${window.location.href}`;
              return;
            } else {
              restartCount += 1;
            }
          } else {
            restartCount = 0;
          }
        }
      };

      setInterval(() => {
        if (sleepCount % 2 === 0) {
          surveyData = getStorage(surveyid, true);
        }
        calculateKioski();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [kioskiRedux]);

  const updateAll = () => {
    if (dataRedux !== null) {
      const surveyDataStorage = getStorage(surveyid, true);

      //alterando o title
      document.title = surveyDataStorage.survey.surveyName;

      //alterando o favicon
      var link = document.querySelector("link[rel~='icon']");
      link.href = surveyDataStorage.survey.design.header.logo;

      if (surveyDataStorage.errors !== "") {
        dispatch(setErrors(surveyDataStorage.errors));
      }

      dispatch(setMaxPage(surveyDataStorage.survey.pages.length - 1));
      dispatch(setSurveyData(surveyDataStorage.survey));
      dispatch(setUserReponses(surveyDataStorage.user));
    }
  };

  useEffect(() => {
    updateAll();
    setTimeout(() => {
      if (listError?.scroll) {
        window.scrollTo(0, listError.scroll);
      }
    }, 500);
    // eslint-disable-next-line
  }, [updateRedux]);

  const sendToAPI = (data) => {
    //dispara para API apenas quando não for preview
    if (pathname.indexOf("preview") <= -1) {
      dispatch(sendDataToDB(data));
    }
  };

  const prevPage = () => {
    const pageToReturn = validatePreviousPage(surveyid);
    if (pageToReturn || pageToReturn === 0) {
      setAnimateBack(true);
      setTimeout(() => {
        let surveyData = getStorage(surveyid, true);
        surveyData.user.currentPage = pageToReturn;
        surveyData.user.progress = "partial";
        surveyData.errors = "";
        setStorage(surveyid, surveyData, true);
        checkMergeCodes(surveyid);
        sendToAPI(surveyData.user);
        window.scrollTo(0, 0);
        dispatch(setUpdate());
        setAnimateBack(false);
      }, 1000);
    } else {
      dispatch(setUpdate());
    }
  };

  const nextPage = () => {
    const pageToGo = validateNextPage(surveyid);
    if (pageToGo !== null && pageToGo !== userResponsesRedux.currentPage) {
      setAnimateForward(true);
      setTimeout(() => {
        extractPersistAnswerOnPage(surveyid, pageToGo);
        let surveyData = getStorage(surveyid, true);
        surveyData.user.currentPage = pageToGo;
        surveyData.user.progress =
          maxPageRedux === pageToGo ? "complete" : "partial";
        setStorage(surveyid, surveyData, true);
        checkMergeCodes(surveyid);
        sendToAPI(surveyData.user);
        window.scrollTo(0, 0);
        dispatch(setUpdate());
        setAnimateForward(false);
      }, 1000);
    } else {
      dispatch(setUpdate());
    }
  };

  const Header = () => {
    const {
      topLine,
      logoHeight,
      height,
      bgColor,
      logo,
      logoMobile,
      shadow,
      fixed,
    } = dataRedux.design.header;

    var rezCalc;
    const resizeCalc = () => {
      let logoImageByScreenCalc =
        logoMobile && logoMobile !== ""
          ? document.documentElement.clientWidth <= 600
            ? logoMobile
            : logo
          : logo;

      rezCalc = setTimeout(() => {
        setLogoHeader(logoImageByScreenCalc);
      }, 300);
    };

    window.addEventListener("resize", () => {
      clearTimeout(rezCalc);
      resizeCalc();
    });

    resizeCalc();

    let styleHeader = {
      borderTop:
        topLine && topLine?.active
          ? `8px solid ${topLine?.color || "#9a2376"}`
          : `none`,
      height: height ? `${height}px` : "80px",
      backgroundColor: bgColor && bgColor.length === 7 ? bgColor : "#ffffff",
      //o undefined força o fixed pois foi o padrão do inicío da aplicação
      position: fixed || fixed === undefined ? "fixed" : "inherit",
      //o undefined força o shadow (padrão do inicío da aplicação)
      boxShadow: `0 5px 5px 0 rgb(0 0 0 / ${
        shadow || shadow === undefined ? "20%" : "0%"
      })`,
    };

    return (
      <header style={styleHeader}>
        <div
          className="content-header"
          style={{
            height: height ? `${height - 8}px` : "80px",
          }}
        >
          <div className="image">
            {logoHeader !== "" && (
              <img
                src={logoHeader}
                style={{ height: logoHeight ? `${logoHeight}px` : "50px" }}
                alt="Logo pesquisa"
              />
            )}
          </div>
        </div>
      </header>
    );
  };

  const Footer = () => {
    const {
      prevButton,
      finishButton,
      nextButton,
      bottomLine,
      colorButtons,
      progressBar,
      bgColor,
      fixed,
      shadow,
    } = dataRedux.design.footer;

    let styleFooter = {
      borderBottom:
        bottomLine && bottomLine?.active
          ? `8px solid ${bottomLine?.color || "#9a2376"}`
          : `none`,
      //o undefined força o fixed (padrão do inicío da aplicação)
      position: fixed || fixed === undefined ? "fixed" : "inherit",
      backgroundColor: bgColor && bgColor.length === 7 ? bgColor : "#f5f5f5",
      //o undefined força o shadow (padrão do inicío da aplicação)
      boxShadow: `0 -5px 5px 0 rgb(0 0 0 / ${
        shadow || shadow === undefined ? "20%" : "0%"
      })`,
    };

    //cor do texto do botão e barra de progresso
    const colorTextButton =
      colorButtons && colorButtons !== "" && colorButtons.length === 7
        ? getLuminosity(colorButtons) === "dark"
          ? "#fff"
          : "#000"
        : "fff";

    //cor do botão e barra de progresso
    const bgColorButtons =
      colorButtons && colorButtons !== "" && colorButtons.length === 7
        ? colorButtons
        : "#9a2376";

    //calculo da progress bar
    const minPieces = 100 / (maxPageRedux + 1);
    const calcProgressBar = (userResponsesRedux.currentPage + 1) * minPieces;

    return (
      <footer style={styleFooter}>
        <div className="content-footer">
          <div className="box-buttons">
            {dataRedux !== null && (
              <>
                {!prevButton?.disable &&
                  userResponsesRedux.currentPage !== 0 &&
                  maxPageRedux !== userResponsesRedux.currentPage && (
                    <Button onClick={() => prevPage()} color={bgColorButtons}>
                      {prevButton?.label || "Voltar"}
                    </Button>
                  )}
                {maxPageRedux !== userResponsesRedux.currentPage &&
                  maxPageRedux - 1 !== userResponsesRedux.currentPage && (
                    <Button onClick={() => nextPage()} color={bgColorButtons}>
                      {nextButton?.label || "Próximo"}
                    </Button>
                  )}
                {maxPageRedux - 1 === userResponsesRedux.currentPage && (
                  <Button onClick={() => nextPage()} color={bgColorButtons}>
                    {finishButton?.label || "Enviar"}
                  </Button>
                )}
              </>
            )}
          </div>
          {progressBar && progressBar !== "disable" && (
            <div className="box-progress">
              {progressBar === "steps" && (
                <div
                  className="steps-bar"
                  style={{ borderColor: bgColorButtons }}
                >
                  <div
                    className="bar"
                    style={{ backgroundColor: bgColorButtons }}
                  >
                    {userResponsesRedux.currentPage + 1}/{maxPageRedux + 1}
                  </div>
                </div>
              )}

              {/* força o progressBar com o padrão do passado com :true */}
              {((progressBar && typeof progressBar === "boolean") ||
                progressBar === "percent") && (
                <div
                  className="static-bar"
                  style={{ borderColor: bgColorButtons }}
                >
                  <div
                    className="bar"
                    style={{
                      backgroundColor: bgColorButtons,
                      width: `${calcProgressBar}%`,
                      color: `${colorTextButton}`,
                    }}
                  >
                    {parseInt(calcProgressBar)}%
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="box-poweredby">
            Powered by Inovyo Experience Management
          </div>
        </div>
      </footer>
    );
  };

  const ContentPage = ({ children }) => {
    const { height, fixed } = dataRedux.design.header;

    const paddingCalc = () => {
      if (fixed || fixed === undefined) {
        return `${height ? height + "px" : "80px"} 0px 100px 0px`;
      } else {
        return "0px";
      }
    };

    return (
      <div
        className="content-page"
        style={{
          padding: paddingCalc(),
        }}
      >
        {children}
      </div>
    );
  };

  const GenerateDesignQuestion = ({ quest }) => {
    if (!quest.active) {
      return <></>;
    }

    switch (quest.type) {
      case "nps":
        return <NPSComponent data={quest} />;
      case "csat":
        return <CSATComponent data={quest} />;
      case "ces":
        return <CESComponent data={quest} />;
      case "radiobox":
        return <Radiobox data={quest} />;
      case "checkbox":
        return <Checkbox data={quest} />;
      case "input":
        const design = quest?.properties;

        if (design?.tel) {
          return <InputPhone data={quest} />;
        } else if (design?.cpf) {
          return <InputCPF data={quest} />;
        } else if (design?.email) {
          return <InputEmail data={quest} />;
        } else if (design?.date || design?.time || design?.datetime) {
          return <DateTime data={quest} />;
        }
        return <Input data={quest} />;
      case "text":
        return <PlainText data={quest} />;
      case "cascading-dropdown":
        return <CascadingDropdown data={quest} />;
      case "dropdown":
        return <Dropdown data={quest} />;
      case "textarea":
        return <Textarea data={quest} />;
      case "optin":
        return <Optin data={quest} />;
      case "language-dropdown":
        return <DropdownLanguage data={quest} />;
      case "image-upload3":
        return <ImageUpload data={quest} />;
      case "reset-button":
        return <ResetButton data={quest} />;
      case "auto-complete":
        return <AutoComplete data={quest} />;
      case "mosaic":
        return <Mosaic data={quest} />;
      case "action":
        return <Action data={quest} />;
      default:
        return <></>;
    }
  };

  return (
    <div id="survey-page">
      {myLoading && (
        <div className="survey-loading">
          <ReactLoading type="bubbles" color="#000000" />
        </div>
      )}
      {!myLoading && userResponsesRedux !== null && dataRedux !== null && (
        <>
          <Header />
          <ContentPage>
            <div className="survey-content">
              {dataRedux !== null &&
                dataRedux.pages.map((contentPage, pageIndex) => (
                  <div
                    key={`page-id-${pageIndex}`}
                    style={{ display: contentPage.active ? "block" : "none" }}
                    className={`animate-box ${
                      animateForward ? "animate-forward" : ""
                    } ${animateBack ? "animate-back" : ""}`}
                  >
                    {contentPage &&
                      pageIndex === userResponsesRedux.currentPage &&
                      contentPage.questions.map(
                        (contentQuestion, questionIndex) => {
                          return (
                            <div
                              className="question-item"
                              key={`question-id-${questionIndex}`}
                            >
                              {contentQuestion.active && (
                                <GenerateDesignQuestion
                                  quest={contentQuestion}
                                />
                              )}
                            </div>
                          );
                        }
                      )}
                  </div>
                ))}
            </div>
          </ContentPage>
          <Footer />
        </>
      )}
      {errorAPIRedux !== "" && (
        <div className="do-not-found">
          <div className="content">
            <div className="logo">
              <img src={LogoInovyoSurvey} alt="Logo Inovyo Survey" />
            </div>
            <div className="message">{errorAPIRedux}</div>
            <div className="footer">
              Inovyo Survey<sup>&copy;</sup> 2022
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaticSurvey;
